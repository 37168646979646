/**
 * @flow
 */

import * as React from 'react';
import Page from 'src/components/Page';
import InternalLink from 'src/components/navigation/InternalLink';
import {linkStyle} from 'src/styles/common';
import {DEFAULT_OG_IMAGE} from 'src/constants';
import SoutherfieldsIcon from 'src/components/icons/SoutherfieldsIcon';

const styles = {
  homeLink: (theme) => ({
    ...linkStyle(theme),
    marginTop: 48,
    width: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  homeLinkLabel: {
    marginTop: 8,
  },
};

export default function NotFoundPage(): React.Node {
  return (
    <Page
      title='404'
      canonicalPath='/404/'
      ogImage={DEFAULT_OG_IMAGE}
      description='Uh oh. Looks like this link is broken! Website for artist and musician Southerfields.'
      verticallyCenterContent={true}
    >
      <div>dang, there ain't nothin here!</div>
      <InternalLink css={styles.homeLink} to='/'>
        <SoutherfieldsIcon />
        <div css={styles.homeLinkLabel}>Home</div>
      </InternalLink>
    </Page>
  );
}
